
import {
  Vue,
  Component,
  Prop,
  PropSync,
} from 'vue-property-decorator';
import { Property, Value } from '@/types/vjsf';
import {
  symbolStringToSymbol,
  getSeparator,
} from '@/helpers/converters/currency';
// eslint-disable-next-line import/no-extraneous-dependencies
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

// Lazy loading imports
const Tooltip = () => import(
  /* webpackChunkName: "form-tooltip" */
  /* webpackPrefetch: false */
  '@/components/Tooltip/Tooltip.vue'
);

@Component({
  name: 'CurrencyField',
  components: {
    Tooltip,
  },
})
export default class CurrencyField extends Vue {
  @PropSync('value', { required: true })
  private currencyValue: Value|undefined;

  @Prop({ required: true })
  private readonly property!: Property;

  @Prop({ required: true })
  private readonly serverLocale!: string;

  @Prop({ required: true })
  private readonly id!: string;

  @Prop({ required: true })
  private readonly required!: boolean;

  private formattedValue = '';

  private isFocussed = false;

  private baseMask = {
    prefix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: getSeparator('group', this.serverLocale),
    allowDecimal: true,
    decimalSymbol: getSeparator('decimal', this.serverLocale),
    decimalLimit: this.property['x-props']?.decimals ?? 2,
    allowNegative: true,
  }

  private maskWhenFocussed = createNumberMask({ ...this.baseMask, includeThousandsSeparator: false });

  private maskWhenBlurred = createNumberMask(this.baseMask);

  created(): void {
    if (typeof this.currencyValue === 'string') {
      this.formattedValue = this.currencyValue
        .replaceAll('.', getSeparator('decimal', this.serverLocale));
    }
  }

  private setCurrencyValue(): void {
    const currencyValue = this.formattedValue
      .replaceAll(this.getCurrencySymbol(), '')
      .replaceAll(getSeparator('group', this.serverLocale), '')
      .replaceAll(getSeparator('decimal', this.serverLocale), '.');

    this.currencyValue = currencyValue !== '' ? currencyValue : null;
  }

  /**
   * Filter the input so that a dot ('.') or a comma (',') input will be changed to the decimal separator
   */
  private filterInput(event: KeyboardEvent): void {
    const input = event.key;

    if ([',', '.'].includes(input) && !this.formattedValue.includes(getSeparator('decimal', this.serverLocale))) {
      event.preventDefault();

      // Only way to change the variable that is set via a v-model
      const el = document.getElementById(this.id) as HTMLInputElement;
      el.value = `${this.formattedValue}${getSeparator('decimal', this.serverLocale)}`;
      el.dispatchEvent(new Event('input'));
    }
  }

  private getCurrencySymbol() {
    return symbolStringToSymbol(this.property['x-props']?.currency ?? 'EUR');
  }
}
